


import { get } from "../utils/http.util";


// const BASE_URL = 'http://localhost:3000';
const BASE_URL = 'https://pol355ivn9.execute-api.us-east-1.amazonaws.com/prod';

export const requestWhitelistToken = async (walletId: any) => {
    const url = `${BASE_URL}/nfts/wave1/token?walletId=${walletId}`
    const response = await get(url);
    return response?.payload;
}


export const getWave1Eligibility = async (walletId: any) => {
    const url = `${BASE_URL}/nfts/wave1/eligibility?walletId=${walletId}`
    const response = await get(url);
    return response?.payload;
}


export const getWave2Eligibility = async (walletId: any) => {
    const url = `${BASE_URL}/profiles/${walletId}/coinFlips/7`
    const response = await get(url);
    return response?.payload;
}

export const requestWhitelistToken2 = async (walletId: any) => {
    const url = `${BASE_URL}/nfts/wave2/token?walletId=${walletId}`
    const response = await get(url);
    return response?.payload;
}