import { Modal } from "react-bootstrap";

export function TakeABreakModal({ styleCss, ...props }) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card card-user shadow-lg">
                    <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')}>
                        <h4 className="">BREAK TIME</h4>
                        Hey there, let's take a break! Hop into our <a href="https://discord.gg/EbKC53qVWZ">discord</a> and come chat with us for a FREE flip.
                    </div>
                    <div className="card-footer">
                    <a href="https://discord.gg/EbKC53qVWZ">
                        <button
                            className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning">
                            JOIN DCF'S DISCORD
                        </button>
                        </a> 
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}