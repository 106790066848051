import { Modal } from "react-bootstrap";

const URL = 'https://discord.gg/EbKC53qVWZ';

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    // console.log(newWindow);
    if (newWindow) newWindow.opener = null
}

export const onClickUrl = (url) => () => openInNewTab(url)

export function CongratsModal({ styleCss, ...props }) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card">
                    <img src="https://i.imgur.com/xQrfdnk.png" className="card-img-top" alt="..."/>
                </div>
                <div className="d-flex flex-row p-2">
                    <button className="btn w-100 btn-lg rounded-0 btn-warning" onClick={props.onClose}>OKAY</button>
                    <button className="btn w-100 btn-lg ms-2 rounded-0 btn-light" onClick={onClickUrl(URL)}>
                        <a
                            href={URL}
                            target="_blank" rel="noopener noreferrer" className="no-decoration">
                            GO TO DISCORD <i className="d-none d-sm-inline-flex fas fa-external-link-alt fa-xs"></i>
                        </a></button>
                </div>
            </Modal.Body>
        </Modal >
    );
}