import { Modal } from "react-bootstrap";

const URL = 'https://twitter.com/degencoinflip/status/1482434527486087168?s=20';

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    // console.log(newWindow);
    if (newWindow) newWindow.opener = null
}

export const onClickUrl = (url) => () => openInNewTab(url)

export function PlayersCardModal({ styleCss, ...props }) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <video autoPlay loop muted playsInline src="https://giant.gfycat.com/WeeklySereneFanworms.mp4" className="bg-video" style={{ width: '100%' }}></video>
                <div className="masthead py-4">
                    <div className="masthead-content">
                        <div className="my-4">
                            <h2 className="text-warning px-3">NEW!</h2>
                            <h3 className="px-3 text-light">DEGEN PLAYER CARDS NFT & LOTTO</h3>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="d-flex flex-row">
                            <button className="btn w-100 btn-lg my-2 rounded-0 btn-warning" onClick={props.onClose}>OKAY</button>
                            <button className="btn w-100 btn-lg my-2 ms-2 rounded-0 btn-light" onClick={onClickUrl(URL)}>
                                <a
                                    href={URL}
                                    target="_blank" rel="noopener noreferrer" className="no-decoration">
                                    LEARN MORE <i className="d-none d-sm-inline-flex fas fa-external-link-alt fa-xs"></i>
                                </a></button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    );
}