



import { Connection, PublicKey } from "@solana/web3.js";
import axios from "axios";
import { get, post } from "../utils/http.util";
import { RPC_URL } from '../utils/constants';
import { Metadata } from "@metaplex-foundation/mpl-token-metadata";
import { Account } from "@metaplex-foundation/mpl-core";

import { Metaplex } from "@metaplex-foundation/js";

const BASE_URL = 'https://api.theblockchainapi.com/v1/solana';
const constants = {
    KEY: "wLLrl4SiaOj0VVj",
    SECRET: "TvI6hgRMeJlYaKQ"
};



// export const getTokenMetadata = async (tokenId: any) => {
//     const url = `${BASE_URL}/nft?mint_address=${tokenId}&network=mainnet-beta`
//     const response = await get(url, {
//         'APISecretKey': constants.SECRET,
//         'APIKeyId': constants.KEY
//     });
//     // console.log(response);
//     return response;
// }


export const getSolanaNftMetadata = async (mint: any) => {
    const connection = new Connection(RPC_URL);
    const metaplex = new Metaplex(connection, { cluster: 'mainnet-beta' });
    const nft = await metaplex.nfts().findByMint(new PublicKey(mint));
    const metadata = nft.metadataTask;
    return metadata;
};