
import './App.css';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { getPhantomWallet, getSolflareWallet } from '@solana/wallet-adapter-wallets';
import { useWallet, WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { CHALLENGES, constants, ERRORS, getOppositeSide, PlayersCardFlipChange, PlayersCardFlipSame, PlayersCardStaticJpegs, PlayersCardTiers, RPC_URL } from './utils/constants';
import { enableMoreDegeneracy, initDegeneracy, getCurrentBalance, signMessage, goDegen, getRewards, rewardExists } from './api-smart-contracts/dcf';
import { Dropdown, Button, Toast, ToastContainer } from 'react-bootstrap';
import { getCoinFlip, getNonce, authorize, getPlayersCard, flipPlayersCard } from './api/degen.service';
import { DateTime } from 'luxon';
import { confetti } from './confetti';
import { loadState, saveState } from './utils/localStorage';
import { queryLatestCoinFlips, queryTopCoinFlips } from './api/queries.service';
import { getProfile } from './api/profiles.service';
import { SocialLinks } from './components/SocialLink';
import { runWorkflow } from "./utils/workflow";
import createCoinFlipWorkflowSteps from "./workflows/createCoinFlip";
import { useMultiAudio } from './hooks/useAudio';
import { AudioButton } from './components/AudioButton';
import { FAQModal } from './components/modals/FAQModal';
import { SlowTPSModal } from './components/modals/SlowTPSModal';
import { ProfileModal } from './components/modals/ProfileModal';
import { ResponsibleModal } from './components/modals/ResponsibleModal';
import { FlipRow } from './components/FlipRow';
import { ResultPage } from './components/pages/ResultPage';
import { HowToPlayModal } from './components/modals/HowToPlayModal';
import { AcceptTermsModal } from './components/modals/AcceptTermsModal';
import { useInterval } from './hooks/useInterval';
import { PlayersCardModal } from './components/modals/PlayersCardModal';
import { createTokenAccount } from './api-smart-contracts/spl-token';
import { NotificationsModal } from './components/modals/NotificationsModal';
import { getWave1Eligibility, getWave2Eligibility } from './api/whitelists';
import Roomlio from './components/Roomlio';
import { TakeABreakModal } from './components/modals/TakeABreakModal';
import { StuckModal } from './components/modals/StuckModal';
import { CongratsModal } from './components/modals/CongratsModal';
import { useWalletNfts } from '@nfteyez/sol-rayz-react';
import { Connection } from '@solana/web3.js';
import { NftCard } from './NftCard';
import { card as mockedPlayersCard } from './players-card.json';
import { SelectPlayersCard } from './components/CardSelector';
import { getSolanaNftMetadata, getTokenMetadata } from './api/blockchainapi.service';

const CHALLENGE_INDEX = 0;
const CURRENT_CHALLENGE = CHALLENGES[CHALLENGE_INDEX];
const PRIMARY_THEME = CURRENT_CHALLENGE.themes[0];
const SECONDARY_THEME = CURRENT_CHALLENGE.themes[1];
const DEFAULT_PFP = "https://i.imgur.com/E3aJ7TP.jpg";

const SIDES_KEY = {
  "H": "HEADS",
  "T": "TAILS"
};

const random = () => Date.now();

// const explorerLink = (mint) => `https://explorer.solana.com/address/${mint}`;
const explorerLink = (mint, wallet) => `https://nfteyez.global/accounts/${wallet}/sol/${mint}`

PlayersCardFlipSame.forEach(i => {
  if (i?.length) {
    var image = new Image();
    image.src = i;
  }
});

PlayersCardFlipChange.forEach(i => {
  if (i?.length) {
    var image = new Image();
    image.src = i;
  }
});

require('@solana/wallet-adapter-react-ui/styles.css');
const wallets = [getPhantomWallet(), getSolflareWallet()];

function App() {
  const wallet = useWallet();
  const [page, setPage] = useState();
  const [price, setPrice] = useState(1)
  const [side, setSide] = useState("H")
  const [winner, setWinner] = useState(null)
  const [degenInstance, setDegenInstance] = useState()
  const [showSeeResult, setSeeResult] = useState()
  const showMessage = () => { return true; };
  const [updated, setUpdated] = useState()
  const [history, setHistory] = useState([]);
  const [manualDegenId, setManualDegenId] = useState('');
  const [showStuckLoading, setShowStuckLoading] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [referenceId, setReferenceId] = useState();
  const [auth, setAuth] = useState(loadState());
  const [homeLoading, setHomeLoading] = useState(false);
  const [rewardLoading, setRewardLoading] = useState(false);
  const [showRewardButton, setShowRewardButton] = useState(false);
  const [showStuckFlippingButton, setShowStuckFlippingButton] = useState(false);
  const [flipResult, setFlipResult] = useState();
  const [recentFlips, setRecentFlips] = useState([]);
  const [topFlips, setTopFlips] = useState([]);
  const [currentWinStreak, setCurrentWinStreak] = useState();
  const [profile, setProfile] = useState({});
  const [coinFlip, setCoinFlip] = useState();
  const [style, setStyle] = useState(localStorage.getItem('style') ?? PRIMARY_THEME);
  const [acceptedTerms, setAcceptedTerms] = useState(localStorage.getItem('terms'));
  const [toggle] = useMultiAudio()
  const [muted, setMuted] = useState(false);

  const [showPleaseWaitButton, setShowPleaseWaitButton] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [coinFlipId, setCoinFlipId] = useState(null);

  const toggleTheme = () => {
    if (style === PRIMARY_THEME) {
      setStyle(SECONDARY_THEME)
      localStorage.setItem('style', SECONDARY_THEME)
    } else {
      setStyle(PRIMARY_THEME)
      localStorage.setItem('style', PRIMARY_THEME)
    }
  }

  const [showProfileModal, setShowProfileModal] = useState(false);
  const handleProfileModalClose = () => setShowProfileModal(false);
  const handleProfileModalOpen = () => setShowProfileModal(true);

  const [showResponsible, setShowResponsible] = useState(false);
  const handleResponsibleClose = () => setShowResponsible(false);
  const handleResponsibleOpen = () => setShowResponsible(true);

  const [showFAQ, setShowFAQ] = useState(false);
  const handleFAQClose = () => setShowFAQ(false);
  const handleFAQOpen = () => setShowFAQ(true);


  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const handleHowToPlayClose = () => setShowHowToPlay(false);
  const handleHowToPlayOpen = () => setShowHowToPlay(true);

  const [showSlowTPS, setShowSlowTPS] = useState(false);
  const handleSlowTPSClose = () => setShowSlowTPS(false);
  const handleSlowTPSOpen = () => setShowSlowTPS(true);

  const [showTerms, setShowTerms] = useState(false);
  const handleTermsClose = () => setShowTerms(false);
  const handleTermsOpen = () => setShowTerms(true);

  const [showPlayersCard, setShowPlayersCard] = useState(false);
  const handlePlayersCardClose = () => setShowPlayersCard(false);
  const handlePlayersCardOpen = () => setShowPlayersCard(true);

  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  const handleNotificationsModalClose = () => setShowNotificationsModal(false);
  const handleNotificationsModalOpen = () => setShowNotificationsModal(true);

  const [showBreakModal, setShowBreakModal] = useState(false);
  const handleBreakModalClose = () => setShowBreakModal(false);
  const handleBreakModalOpen = () => setShowBreakModal(true);

  const [showStuckModal, setShowStuckModal] = useState(false);
  const handleStuckModalClose = () => setShowStuckModal(false);
  const handleStuckModalOpen = () => setShowStuckModal(true);

  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const handleCongratsModalClose = () => setShowCongratsModal(false);
  const handleCongratsModalOpen = () => setShowCongratsModal(true);

  const [toastShow, setToastShow] = useState(false);
  const [errorToastShow, setErrorToastShow] = useState(false);
  const [reclaimedToastShow, setReclaimedToastShow] = useState(false);
  const [alreadyClaimedToastShow, setAlreadyClaimedToastShow] = useState(false);
  const [youLostToastShow, setYouLostToastShow] = useState(false);
  const [youWonToastShow, setYouWonToastShow] = useState(false);

  const [genericToastShow, setGenericToastShow] = useState(false);
  const [genericError, setGenericError] = useState();

  const [showBalance, setShowBalance] = useState(null);
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    (async () => {
      if (
        !wallet ||
        !wallet.publicKey
      ) {
        return;
      }

      const publicKey = wallet.publicKey.toString();
      // const profile = await getProfile(publicKey);
      // console.log(profile);
      // setProfile(profile);

      // if (!localStorage.getItem('playersCard')) {
      //   setTimeout(() => {
      //     localStorage.setItem('playersCard', true)
      //     handlePlayersCardOpen();
      //   }, 900);
      // }

    })();
  }, [wallet]);

  // useEffect(async () => {
  //   await getRecentFlips(12);
  //   await getTopFlips(2000);
  // }, []);

  const getRecentFlips = () => {}
  // const getRecentFlips = async (limit = 12) => setRecentFlips(await queryLatestCoinFlips(DateTime.utc().minus({ hours: 1 }), limit));
  const getTopFlips = async (limit) => {
    const topFlips = await queryTopCoinFlips(DateTime.utc().minus({ days: 1 }), 5, limit, CURRENT_CHALLENGE.id);
    if (topFlips?.length) {
      setTopFlips(topFlips?.slice(0, 10));
    }
  }


  const claimRewards = async () => {
    const hasReward = await rewardExists(wallet);
    if (!hasReward) {
      // setGenericError(ERRORS.REWARD_PROCESSING);
      // setGenericToastShow(true);
      await getCoinFlip(wallet?.publicKey?.toString(), auth?.idToken);
      setShowPleaseWaitButton(true);
      return;
    }

    setRewardLoading(true);
    try {
      await getRewards(wallet);
      setRewardLoading(false);
      handleSetCurrentBalance();
      resetAll();
      setYouWonToastShow(true);
    } catch {
      setRewardLoading(false);
      setGenericError(ERRORS.REWARD_PROCESSING);
      setGenericToastShow(true);
    }
  }

  const resetAll = () => {
    setPage("dubornut");
    setWinner(null);
    setDegenInstance(null);
    setSeeResult(false)
    setShowStuckLoading(false)
    setReferenceId(null);
    setShowRewardButton(false);
    setFlipResult(null);
  }

  const retryFlip = async () => {
    setShowStuckFlippingButton(false);
    await depositAndCreateCoinFlip(side, price);
  }

  const depositAndCreateCoinFlip = async (selectedSide, price, prevWinStreak = currentWinStreak) => {
    setSide(selectedSide);
    setPrice(price);
    setShowStuckFlippingButton(false);

    const workflowParams = {
      headers: {
        token: auth?.idToken
      },
      deposit: {
        amount: price
      },
      create: {
        side: selectedSide,
        challengeId: CURRENT_CHALLENGE.id
      }
    };

    setPage("flip");
    let response;
    try {
      response = await runWorkflow(createCoinFlipWorkflowSteps, workflowParams, wallet);
    } catch (error) {
      console.log(error);
      setGenericError(error);
      if (error?.code === ERRORS.DEPOSIT_FAILED.code) {
        setGenericToastShow(true);
        setPage("dubornut")
      }
      if (error?.code === ERRORS.PREV_PROCESSING.code) {
        setGenericToastShow(true);
        setSide(error?.coinFlip?.side ?? side);
        setPrice(error?.coinFlip?.amount ?? price);
        setWinner(error?.coinFlip?.won ? error?.coinFlip?.side : getOppositeSide(error?.coinFlip?.side));
        setPage("result")
      }
      else {
        setGenericToastShow(true);
        setShowStuckFlippingButton(true);
      }
      return;
    }


    const {
      rewardsSkip,
      create: coinFlip
    } = response;

    setSide(coinFlip?.side ?? side);
    setPrice(coinFlip?.amount ?? price);

    if (coinFlip?.id) {
      setCoinFlipId(coinFlip?.id);
    }

    if (coinFlip?.processing) {
      setGenericError(ERRORS.PREV_PROCESSING);
      setGenericToastShow(true);
      setPage("dubornut");
      return;
    }

    if (rewardsSkip) {

      // state
      setWinner(selectedSide);

      if (prevWinStreak > 1) {
        // confetti
        confetti.start();
        setTimeout(function () {
          confetti.stop()
        }, 5000);
        if (prevWinStreak == 2) {
          playSoundFx(3);
        } else {
          playSoundFx(4);
        }
      } else {
        playSoundFx(2);
      }

      // change page
      setPage("result");
      return;
    }

    setShowPleaseWaitButton(true);
    if (coinFlip?.id) {
      setCurrentWinStreak(0);
      setWinner(selectedSide == "H" ? "T" : "H");

      if (coinFlip?.won) {
        setWinner(selectedSide);

        if (coinFlip?.winStreak > 1) {
          setCurrentWinStreak(coinFlip?.winStreak);
          // confetti
          confetti.start();
          setTimeout(function () {
            confetti.stop()
          }, 5000);
          if (coinFlip?.winStreak == 2) {
            playSoundFx(3);
          } else {
            playSoundFx(4);
          }
        } else {
          playSoundFx(2);
        }
      }
      else if (!coinFlip?.processing) {
        setYouLostToastShow(true);
        if (prevWinStreak > 1) {
          playSoundFx(1);
        } else {
          playSoundFx(0);
        }
      }

      if (coinFlip?.winStreak == 7) {
        setTimeout(() => {
          handleCongratsModalOpen();
        }, 1000);
      }

      if (coinFlip?.loseStreak == 7) {
        setTimeout(() => {
          handleCongratsModalOpen();
        }, 1000);
      }

      setPage("result");
    }
  }

  const [showClaimToken, setShowClaimToken] = useState(false);
  const [claimLoading, setClaimLoading] = useState(false);
  const [hideCoinAnimation, setHideCoinAnimation] = useState(false);
  const [isNotEligible, setIsNotEligble] = useState(false);
  const [alreadyClaimed, setAlreadyClaimed] = useState(false);

  const checkEligibility = async () => {
    let response = null;
    try {
      response = await getWave2Eligibility(wallet?.publicKey?.toString());
    } catch {
      setIsNotEligble(true);
      return;
    }
    if (!response?.coinFlips?.length || response?.isClaimed) {
      setAlreadyClaimed(response?.isClaimed);
      setIsNotEligble(true);
      return;
    }

    setShowClaimToken(true);
    setTimeout(function () {
      setHideCoinAnimation(true);
    }, 3000);
    confetti.start();
    setTimeout(function () {
      confetti.stop()
    }, 5000);
    playSoundFx(5);
  }

  const claimToken = async () => {
    setClaimLoading(true);
    try {
      await createTokenAccount(wallet);
    } catch {
    }
    setClaimLoading(false);
    setToastShow(true);
  }

  const beginFlipping = async () => {
    if (!wallet.connected) return;
    const balance = await getCurrentBalance(wallet);
    setCurrentBalance(balance);
    setHomeLoading(true);

    let accepted = acceptedTerms;
    let authResponse;
    if (!auth || DateTime.fromSeconds(auth?.exp) < DateTime.utc()) {
      // const nonceResponse = await getNonce(wallet?.publicKey?.toString());
      // const nonce = nonceResponse?.nonce;
      // accepted = nonceResponse?.acceptedTerms;
      let signedMessage;
      try {
        signedMessage = await signMessage(69420);
      } catch {
        setHomeLoading(false);
        return;
      }

      authResponse = await authorize(wallet?.publicKey?.toString(), signedMessage.signature);
      saveState(authResponse);
      setAuth(authResponse);
    } else {
      authResponse = auth;
    }

    let existingCoinFlip;
    try {
      existingCoinFlip = await getCoinFlip(wallet?.publicKey?.toString(), authResponse?.idToken);
      // console.log(existingCoinFlip.tx ?? existingCoinFlip.id);
      setCoinFlip(existingCoinFlip);
      setCoinFlipId(existingCoinFlip.id);
    } catch {

    }

    if (existingCoinFlip) {
      // setReclaimedToastShow(true);
      setCurrentWinStreak(existingCoinFlip?.winStreak);
      await depositAndCreateCoinFlip(existingCoinFlip?.side, existingCoinFlip?.amount, existingCoinFlip?.winStreak)
    } else {
      setPage("dubornut")
      if (!accepted) {
        setTimeout(() => {
          handleTermsOpen();
        }, 690);
      } else {
        setTimeout(() => {
          handleSlowTPSOpen();
        }, 690);
      }
    }

    handleSetCurrentBalance();

    // if (!isEligible) {
    //   const eligible = await getWave1Eligibility(wallet?.publicKey?.toString());
    //   setIsEligible(eligible);
    // }
  }

  const BalanceStatusInfo = () => {
    const DEFAULT_INTERVAL = 5000;
    useInterval(async () => {
      if (wallet) {
        const balance = await getCurrentBalance(wallet);
        setCurrentBalance(balance);
      }
    }, DEFAULT_INTERVAL);

    return (
      <h5 className="mt-1 balance-text mb-0">SOL {currentBalance.toFixed(5)} </h5>
    )
  }

  const handleSetCurrentBalance = async () => {
    setShowBalance(true);
  }

  const playSoundFx = (key) => {
    if (muted) return;
    toggle(key);
  }

  const Modals = () => {
    return (
      <>
        {
          showProfileModal &&
          <ProfileModal
            show={showProfileModal}
            wallet={wallet}
            profile={profile}
            defaultProfilePicture={DEFAULT_PFP}
            onHide={handleProfileModalClose}
            setProfile={setProfile}
            styleCss={style}
          />
        }
        {
          showResponsible &&
          <ResponsibleModal
            show={showResponsible}
            onHide={handleResponsibleClose}
            styleCss={style}
          />
        }
        {
          showSlowTPS &&
          <SlowTPSModal
            show={showSlowTPS}
            onClose={() => handleSlowTPSClose()}
            styleCss={style}
          />
        }
        {
          showFAQ &&
          <FAQModal
            show={showFAQ}
            onHide={() => handleFAQClose()}
            wallet={wallet}
            styleCss={style}
          />
        }
        {
          showHowToPlay &&
          <HowToPlayModal
            show={showHowToPlay}
            onHide={() => handleHowToPlayClose()}
            styleCss={style}
          />
        }
        {
          showTerms &&
          <AcceptTermsModal
            show={showTerms}
            onClose={() => handleTermsClose()}
            styleCss={style}
          />
        }
        {
          showPlayersCard &&
          <PlayersCardModal
            show={showPlayersCard}
            onClose={() => handlePlayersCardClose()}
            styleCss={style}
          />
        }
        {
          showBreakModal &&
          <TakeABreakModal
            show={showBreakModal}
            onHide={() => handleBreakModalClose()}
            styleCss={style}
          />
        }
        {
          showStuckModal &&
          <StuckModal
            show={showStuckModal}
            onHide={() => handleStuckModalClose()}
            styleCss={style}
          />
        }
        {
          showCongratsModal &&
          <CongratsModal
            show={showCongratsModal}
            onClose={() => handleCongratsModalClose()}
            styleCss={style}
          />
        }
        {/* {
          showNotificationsModal &&
          <NotificationsModal
            show={showNotificationsModal}
            wallet={wallet}
            used={isEligible?.used}
            onHide={() => handleNotificationsModalClose()}
            onSuccess={() => {handleNotificationsModalClose(); setToastShow(true);}}
            styleCss={style}
          />
        } */}
      </>
    );
  }

  const Toasts = () => {
    return (
      <>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast className="d-inline-block m-1" onClose={() => setGenericToastShow(false)} show={genericToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto"><span className="fas fa-ban text-danger"></span> {genericError?.type}</strong>
            </Toast.Header>
            <Toast.Body>
              {genericError?.message}
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setToastShow(false)} show={toastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">Successfully claimed WL token! Airdrop incoming...</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setErrorToastShow(false)} show={errorToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">You're broke! Not enough SOL.</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setAlreadyClaimedToastShow(false)} show={alreadyClaimedToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">Solana is still processing your previous coin flip.</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setReclaimedToastShow(false)} show={reclaimedToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">Showing your previous coin flip.</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setYouWonToastShow(false)} show={youWonToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">You doubled your money. Congrats degen.</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
        <ToastContainer position="top-start" className="p-3 top-index">
          <Toast onClose={() => setYouLostToastShow(false)} show={youLostToastShow} delay={5000} autohide>
            <Toast.Header>
              <strong className="me-auto">Better luck next time fucker.</strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
      </>
    );
  }

  const showPlayersCardPage = () => {
    setPage("selection");
  }

  const ThemeToggler = () => {
    return (
      <button className="btn btn-outline-dark" onClick={() => toggleTheme()}>
        {style === SECONDARY_THEME ? SECONDARY_THEME.toLocaleUpperCase() : PRIMARY_THEME.toLocaleUpperCase()} <i className={"fa-xs " + (style === PRIMARY_THEME ? "fas fa-moon" : "far fa-sun")}></i>
      </button>
    );
  }

  const LeaderboardSection = () => {
    const DEFAULT_INTERVAL = 10000;
    useInterval(async () => {
      await getRecentFlips(12);
    }, DEFAULT_INTERVAL);

    return (
      <>
        {/* <div className="mt-3">
        <ul className="list-group leaderboard-list border">
          <li className="list-group-item d-flex flex-row justify-content-start">
            <button onClick={() => { setLeaderboardType('new') }} className={`btn btn${leaderboardType == 'new' ? '' : '-outline'}-dark me-2`}>RECENT</button>
            <button onClick={() => setLeaderboardType('top')} className={`btn btn${leaderboardType == 'top' ? '' : '-outline'}-dark me-2`}>TOP WINS</button>
            <button type="button" className={"btn-close ms-auto my-auto " + (style === 'dark' ? "btn-close-white" : "btn-close")} aria-label="Close" onClick={() => setShowLeaderboard(false)}></button>
          </li>
        </ul>
      </div> */}
        <ul className="list-group mt-1 leaderboard-list border">
          {
            leaderboardType == 'new' && recentFlips?.map(flip => {
              return (<FlipRow flip={flip} key={flip.id} defaultProfilePicture={DEFAULT_PFP} slug={CURRENT_CHALLENGE.slug}></FlipRow>)
            })
          }
          {
            leaderboardType == 'top' && topFlips.map(flip => {
              return (<FlipRow flip={flip} type={`winStreak`} key={flip.id} defaultProfilePicture={DEFAULT_PFP} slug={CURRENT_CHALLENGE.slug}></FlipRow>)
            })
          }
        </ul>
      </>

    );
  }

  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [leaderboardType, setLeaderboardType] = useState("new");
  const TopRightBar = () => {
    return (
      <div className="social-icons">
        <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
          <div className="mt-3 d-flex flex-column shortcut-row">

            <div className="d-flex flex-row mb-2 toolbar">
              {
                <>
                  <a
                    href="#"
                    className="ms-2">
                    <button
                      className="btn btn-dark"
                      onClick={async () => {
                        if (!showLeaderboard) {
                          await getRecentFlips(12)
                          setShowLeaderboard(true);
                          setLeaderboardType('new');
                        }
                        else if (showLeaderboard && leaderboardType === 'top')
                          setLeaderboardType('new');
                        else setShowLeaderboard(false)
                      }}>
                      <span className="d-none d-sm-inline-flex">LIVE </span>
                      PLAYS <i className="d-none d-sm-inline-flex fas fa-caret-down fa-xs"></i>
                    </button>
                  </a>
                  <a
                    href="#"
                    className="ms-2">
                    <button
                      className="btn btn-dark"
                      onClick={() => {
                        if (!showLeaderboard) {
                          setShowLeaderboard(true);
                          setLeaderboardType('top');
                        }
                        else if (showLeaderboard && leaderboardType === 'new')
                          setLeaderboardType('top');
                        else setShowLeaderboard(false)
                      }}>
                      <span className="d-none d-sm-inline-flex">WIN </span>
                      STREAKS <i className="d-none d-sm-inline-flex fas fa-trophy fa-xs"></i>
                    </button>
                  </a>
                </>
              }
              <a
                href={`https://degencoinflip.com/`}
                target="_blank" rel="noopener noreferrer"
                className="ms-2">
                <button className="btn btn-dark">
                  PLAY <i className="d-none d-sm-inline-flex fas fa-external-link-alt fa-xs"></i>
                </button>
              </a>
            </div>
            {
              showBalance &&
              <BalanceStatusInfo></BalanceStatusInfo>
            }
            {
              showLeaderboard &&
              <>
                <LeaderboardSection></LeaderboardSection>
              </>
            }
          </div>
        </div>
      </div>
    );
  }

  const BottomRightBar = () => {
    return (
      <div className="social-icons-bottom-right">
        <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
          <div className="mt-3 d-flex flex-column shortcut-row">
            <SocialLinks social={CURRENT_CHALLENGE.social}></SocialLinks>
          </div>
        </div>
      </div>
    );
  }

  const TopLeftBar = () => {
    return (
      <div className="social-icons-left">
        <div className="d-flex flex-row flex-sm-column justify-content-start align-items-center h-100">
          <div className="mt-3 d-flex flex-column shortcut-row">
            <div className="d-flex flex-row mb-2 toolbar">
              <ThemeToggler></ThemeToggler>
            </div>
          </div>
        </div>
      </div>
    );
  }



  const [playersCardNFT, setPlayersCardNFT] = useState(null);
  const [playersCard, setPlayersCard] = useState(null);
  const [count, setCount] = useState(0);
  const goToFlipPage = async (card) => {
    setPlayersCard(null);
    setPlayersCardNFT(null);
    setCount(0);
    setPlayersCardNFT(card);
    let pcard = null;
    let flippable = true;
    try {
      const response = await getSolanaNftMetadata(card?.mint);
      console.log(response);
      // const { off_chain_data } = response;
      const { attributes } = response?.result;

      const flipsRemainingAttr = attributes.find(attr => attr.trait_type === 'FLIPS REMAINING');

      if (!flipsRemainingAttr) {
        flippable = true;
        setCount(0);
      }
      else if (flipsRemainingAttr?.value === 0) {
        flippable = false;
        setCount(5);
      } 
      else {
        setCount(5-flipsRemainingAttr?.value);
      }

      // const response = await getPlayersCard(card?.mint);
      pcard = response?.result;
      // flippable = response?.count < 5;
    }
    catch (err) {
      console.log(err);
      pcard = {
        tokenId: card?.mint,
        ...mockedPlayersCard
      };
      flippable = true;
    }

    console.log(pcard);
    const { attributes } = pcard;
    const tierAttr = attributes.find(attr => attr.trait_type?.toUpperCase() === 'TIER');
    console.log(tierAttr);
    setPlayersCard({ 
      ...pcard, 
      tokenId: card?.mint,
      tier: tierAttr?.value?.toUpperCase()
    });
    if (flippable) {
      setPage("bet");
    } else {
      setPage("selection");
      setGenericError(ERRORS.NO_MORE_FLIPS);
      setGenericToastShow(true);
    }

  }

  const [flipGif, setFlipGif] = useState(null)
  const [commenceLoading, setCommenceLoading] = useState(null)
  const commenceFlip = async () => {
    setCommenceLoading(true);


    let flippedResult;
    try {
      // const nonceResponse = await getNonce(wallet?.publicKey?.toString());
      // const nonce = nonceResponse?.nonce;
      const signedMessage = await signMessage(69420);
      flippedResult = await flipPlayersCard(
        playersCard?.tokenId, 
        wallet?.publicKey.toString(), 
        side,
        signedMessage.signature
      );
    } catch {
      setPage("selection");
      setGenericError(ERRORS.NO_MORE_FLIPS);
      setGenericToastShow(true);
      setCommenceLoading(false);
      return;
    }

    console.log(flippedResult);
    if (flippedResult?.won) {
      console.log(PlayersCardFlipChange[PlayersCardTiers[playersCard?.tier]])
      setFlipGif(PlayersCardFlipChange[PlayersCardTiers[playersCard?.tier]]);
      setPlayersCard(flippedResult);
      // confetti
      confetti.start();
      setTimeout(function () {
        confetti.stop()
      }, 5000);

      if (flippedResult?.tierId == 2) {
        playSoundFx(3);
      } else {
        playSoundFx(4);
      }
      setPage("see-result");
    } else {
      console.log(PlayersCardFlipSame[PlayersCardTiers[playersCard?.tier]])
      setFlipGif(PlayersCardFlipSame[PlayersCardTiers[playersCard?.tier]] + '?' + random());
      playSoundFx(1);
      setPage("see-losing-result");
    }
    setCommenceLoading(false);
  }

  return (
    <React.Fragment>
      <div className={style}>
        {/* <TopRightBar></TopRightBar> */}
        <TopLeftBar></TopLeftBar>
        <BottomRightBar></BottomRightBar>
        <div className="text-center body-wrapper h-100vh h-100">
          <Modals></Modals>
          <Toasts></Toasts>
          {
            (!page || page === "home") &&
            <div className="faq">
              <h3>DCF Players Card</h3>
              <h5>The Flip</h5>
              {
                showClaimToken &&
                <img className="logo rounded-circle" src={constants.BUTTONS.BabyCoin} alt="" width="256" height="256" />
              }
              <div className="form-signin text-center">
                {
                  !showClaimToken &&
                  <>
                    <video autoPlay loop muted playsInline src="https://giant.gfycat.com/MilkyBitesizedDegus.mp4" className="border rounded-sm" style={{ width: '100%' }}></video>
                    {
                      !wallet.connected &&
                      <WalletMultiButton style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    }
                    {
                      wallet.connected &&
                      <>
                        {
                          !homeLoading &&
                          <>
                            {
                              !isNotEligible &&
                              <button
                                className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning"
                                onClick={() => showPlayersCardPage()}>
                                FLIP CARD
                              </button>
                            }
                            {
                              isNotEligible &&
                              <>
                                <button
                                  className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-danger shake-it disabled">
                                  {alreadyClaimed ? 'ALREADY CLAIMED' : 'NOT ELIGIBLE'}
                                </button>
                                <a href={`https://solscan.io/token/2ADnyCjfpdyW688KuD7eQFctasVh21A12ENBLicR21Jh`} target="_blank" className="no-decoration"><h6 className="mt-3 text-secondary">View token on solscan.io</h6></a>
                                <h6 className="text-secondary">2ADnyCjfpdyW688KuD7eQFctasVh21A12ENBLicR21Jh</h6>
                              </>

                            }
                          </>
                        }
                        {
                          homeLoading &&
                          <div className="fa-2x">
                            <i className="fas fa-circle-notch fa-spin"></i>
                          </div>
                        }
                      </>
                    }
                  </>
                }
                {
                  showClaimToken &&
                  <>
                    {
                      !hideCoinAnimation &&
                      <img src="https://i.imgur.com/rX9Ak1B.gif" className="bg-video" style={{ width: '100%' }} />
                    }
                    <h1>CONGRATS</h1>
                    <h4>You are eligible!</h4>
                    {
                      !claimLoading &&
                      <>
                        <button
                          className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning"
                          onClick={() => claimToken()}>
                          CLAIM WL TOKEN
                        </button>
                      </>
                    }
                    {
                      claimLoading &&
                      <div className="fa-2x">
                        <i className="fas fa-circle-notch fa-spin"></i>
                      </div>
                    }
                    <a href={`https://solscan.io/token/2ADnyCjfpdyW688KuD7eQFctasVh21A12ENBLicR21Jh`} target="_blank" className="no-decoration"><h6 className="mt-3 text-secondary">View token on solscan.io</h6></a>
                    <h6 className="text-secondary">2ADnyCjfpdyW688KuD7eQFctasVh21A12ENBLicR21Jh</h6>
                  </>
                }
              </div>
              {/* <div className="accordion text-center" id="myAccordion">
                <h6 className=""><a href="https://about.degencoinflip.com" target="_blank">ABOUT</a> | <a href="#" onClick={() => handleFAQOpen()}>FAQ</a> | <a href="#" onClick={() => handleHowToPlayOpen()}>HOW TO PLAY</a> | <a href="#" onClick={() => handleResponsibleOpen()}>FLIP RESPONSIBLY</a></h6>
              </div> */}
            </div>
          }
          {
            (page === "selection") &&
            <div className="faq">
              <h3>SELECT PLAYERS CARD</h3>
              <h6>MAXIMUM 5 FLIPS PER CARD, FOREVER.</h6>
              <SelectPlayersCard
                wallet={wallet}
                onContinue={goToFlipPage}>
              </SelectPlayersCard>
            </div>
          }
          {
            (page === "bet") &&
            <div className="faq">
              <div className="form-signin text-center">
                <h2>FLIP & UPGRADE YOUR CARD</h2>
                {
                  playersCard != null &&
                  <>
                    <img className="logo rounded-circle" src={PlayersCardStaticJpegs[PlayersCardTiers[playersCard?.tier]]} alt="" width="256" height="256" />
                    <a href={explorerLink(playersCardNFT?.mint, wallet?.publicKey?.toString())} target="_blank"><h6 className="mb-5">View on NFT Eyez</h6></a>
                  </>
                }
                <h3 className="mt-2 mt-sm-5">CHOOSE A SIDE</h3>
                <div className="row mb-3">
                  <div className="col-6">
                    <img onClick={() => setSide("H")} className={"cursor-pointer double-button" + (side == "H" ? " selected" : "")} src={constants.BUTTONS.Heads} alt="heads" width="100%" height="100%" />
                  </div>
                  <div className="col-6">
                    <img onClick={() => setSide("T")} className={"cursor-pointer double-button" + (side == "T" ? " selected" : "")} src={constants.BUTTONS.Tails} alt="tails" width="100%" height="100%" />
                  </div>
                </div>
                <hr />
                <img onClick={() => setPage("beginflip")} disabled={!side?.length} className="cursor-pointer double-button" src={constants.BUTTONS.FlipYourCard} alt="" width="100%" height="100%" />
                <h6 className="mt-3"><a href="#" onClick={() => setPage("selection")}>GO BACK</a></h6>
              </div>
            </div>
          }
          {
            (page === "beginflip") &&
            <div className="faq">
              <div className="form-signin text-center">
                <h3>YOU SELECTED {side == "H" ? "HEADS" : "TAILS"}</h3>
                {
                  playersCard != null &&
                  <>
                    <img className="logo rounded-circle shake-it" src={PlayersCardStaticJpegs[PlayersCardTiers[playersCard?.tier]]} alt="" width="256" height="256" />
                    <a href={explorerLink(playersCardNFT?.mint, wallet?.publicKey?.toString())} target="_blank"><h5 className="mb-5">View on NFT Eyez</h5></a>
                  </>
                }
                {
                  !commenceLoading &&
                  <>
                  {
                    5 - count == 1 &&
                    <h6 className="mt-3">THIS IS YOUR LAST FLIP. READY?</h6>
                  }
                  {
                    5 - count > 1 &&
                    <h6 className="mt-3">YOU HAVE {5 - count} FLIPS REMAINING. READY?</h6>
                  }
                  {
                    5 - count == 0 &&
                    <h6 className="mt-3">YOU HAVE NO MORE FLIPS.</h6>
                  }
                    <img onClick={() => commenceFlip()} className="cursor-pointer double-button" src={constants.BUTTONS.Yolo} alt="" width="100%" height="100%" />
                    <h6 className="mt-3"><a href="#" onClick={() => setPage("bet")}>GO BACK</a></h6>
                  </>
                }
                {
                  commenceLoading &&
                  <div className="fa-2x">
                    <i className="fas fa-circle-notch fa-spin"></i>
                  </div>
                }
              </div>
            </div>
          }
          {
            (page === "see-losing-result") &&
            <div className="faq">
              <div className="form-signin text-center">
                <h4 className="text-danger">YOU LOST FUCKER</h4>
                {
                  flipGif != null &&
                  <>
                    <img className="logo rounded-circle" src={flipGif} alt="" width="256" height="256" />
                  </>
                }
                <h3>NO UPGRADE THIS TIME</h3>
                <img onClick={() => { goToFlipPage(playersCardNFT); setFlipGif(null) }} className="cursor-pointer double-button" src={constants.BUTTONS.TryAgain} alt="" width="100%" height="100%" />
                <h6 className="mt-3">Phantom caches images and will update periodically.</h6>
                <a href={explorerLink(playersCardNFT?.mint, wallet?.publicKey?.toString())} target="_blank"><h6 className="mb-5">View on NFT Eyez</h6></a>
              </div>
            </div>
          }
          {
            (page === "see-result") &&
            <div className="faq">
              <div className="form-signin text-center">
                <h4>CONGRATS</h4>
                {
                  flipGif != null &&
                  <>
                    <img className="logo rounded-circle" src={flipGif} alt="" width="256" height="256" />
                  </>
                }
                <h3>YOU'RE NOW THE <a href={explorerLink(playersCardNFT?.mint, wallet?.publicKey?.toString())} target="_blank">{playersCard?.tier?.replace(/_/g, ' ')}</a> TIER</h3>
                <img onClick={() => { goToFlipPage(playersCardNFT); setFlipGif(null) }} className="cursor-pointer double-button" src={constants.BUTTONS.TryAgain} alt="" width="100%" height="100%" />
                <h6 className="mt-3">Phantom caches images and will update periodically.</h6>
                <a href={explorerLink(playersCardNFT?.mint, wallet?.publicKey?.toString())} target="_blank"><h6 className="mb-5">View on NFT Eyez</h6></a>
              </div>
            </div>
          }
        </div>
      </div>

    </React.Fragment>
  )
}



const AppWithProvider = () => (
  <ConnectionProvider endpoint="http://127.0.0.1:8899">
    <WalletProvider wallets={wallets} autoConnect>
      <WalletModalProvider>
        <App />
      </WalletModalProvider>
    </WalletProvider>
  </ConnectionProvider>
)

export default AppWithProvider;