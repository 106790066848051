


import { CHALLENGES } from "../utils/constants";
import { get } from "../utils/http.util";
// const BASE_URL = 'http://localhost:3000';
const BASE_URL = 'https://pol355ivn9.execute-api.us-east-1.amazonaws.com/prod';

export const queryLatestCoinFlips = async (startTime: any, limit: any = 20, Authorization: any) => {
  const url = `${BASE_URL}/coinFlips/history?startTime=${startTime}&limit=${limit}`
  const response  = await get(url, { Authorization });
  // console.log(response);
  return response?.payload;
}


export const queryTopCoinFlips = async (startTime: any, winStreak: any = 2, limit: any = 20, challengeId: any = CHALLENGES[0].id, Authorization: any) => {
  const url = `${BASE_URL}/coinFlips/top?startTime=${startTime}&limit=${limit}&removeDups=true&winStreak=${winStreak}&challengeId=${challengeId}`
  const response  = await get(url, { Authorization });
  // console.log(response);
  return response?.payload;
}