export const RPC_URL = "https://solana-api.projectserum.com";

export const faqs = [
  { q: "What is Degen Coin Flip (DCF)?", a: "Degen Coin Flip is a smart contract that allows users to play Double or Nothing with their Solana tokens. Odds are 50/50 with a 3.5% fee that goes to DCF NFT holders." },
  { q: "How do I know I can Trust DCF?", a: "DCF has over 133K SOL (~20 Million USD) flipped since we started and we are the trusted platform on Solana.\n\nThe DCF Team and DCF NFT holders' have aligned incentives for the game to have exactly 50/50 odds.\n\nOur House and Fee wallets are all public and every transaction can be reviewed by anyone." },
  { q: "Where can I track transactions?", a: "House Wallet:\nhttps://explorer.solana.com/address/DLq9BPETifWi56sxmW29FVCYGhpJSupq9v6uC5cYxgQA\n\nFee Wallet:\nhttps://explorer.solana.com/address/Fe77Txknt6mLx52wNq58TJ9Arwe6oEyDHUAaG7c1xo5" },
  { q: "Where can I learn more?", a: "Join us on discord, there will always be someone to help you out.\nhttps://discord.gg/f5JGjGysHU" },
];

export const CHALLENGES = [
  {
    "id": "00000000-0000-0000-0000-000000042069",
    "coinImageUrl": "https://i.imgur.com/896fn7R.png",
    "logoUrl": "https://i.imgur.com/896fn7R.png",
    "name": "Degen",
    "profileImageUrl": "https://i.imgur.com/WtWzHUL.png",
    "url": "https://degencoinflip.com",
    "themes": ["light", "dark"],
    "social": {
      "twitterUrl": "https://twitter.com/degencoinflip",
      "marketplaceUrl": "https://magiceden.io/marketplace/degencoinflipcom",
      "discordUrl": "https://discord.gg/GnNacaEAyP"
    },
    "slug": "wagmi"
  },
  {
    "id": "d2e33f7c-95e5-4125-87e0-ce89b500b836",
    "coinImageUrl": "https://i.imgur.com/uxqwMQZ.png",
    "logoUrl": "https://i.imgur.com/uxqwMQZ.png",
    "name": "SolChicks",
    "profileImageUrl": "https://i.imgur.com/uxqwMQZ.png",
    "url": "https://flip.solchicks.io",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/SolChicksNFT",
      "marketplaceUrl": "https://magiceden.io/marketplace/solchicks",
      "discordUrl": "https://discord.gg/solchicks"
    },
    "slug": "solchicks"
  },
  {
    "id": "afb2ea05-24d8-47d9-92e6-2cf659b19883",
    "coinImageUrl": "https://i.imgur.com/b8pipWF.png",
    "logoUrl": "https://i.imgur.com/ciLzClZ.jpg",
    "name": "Degen Dex",
    "profileImageUrl": "https://i.imgur.com/b8pipWF.png",
    "url": "https://flip.degendex.io",
    "themes": ["dark", "light"],
    "slug": "degendex",
    "social": {
      "twitterUrl": "https://twitter.com/DegenDex",
      "marketplaceUrl": "https://magiceden.io/marketplace/degen_coin_flip",
      "discordUrl": "https://discord.gg/3WcY4JjFeB"
    }
  },
  {
    "id": "2e40f832-9c3f-4c90-b208-43d37afcab95",
    "coinImageUrl": "https://i.imgur.com/PcpvtJg.png",
    "logoUrl": "https://c.tenor.com/caKYaPNBiCQAAAAC/meerkat-mmcc.gif",
    "name": "Meerkat Millionaires",
    "profileImageUrl": "https://i.imgur.com/PcpvtJg.png",
    "url": "https://flip.meerkatmillionaires.club",
    "themes": ["dark", "light"],
    "slug": "mmcc",
    "social": {
      "twitterUrl": "https://twitter.com/mmccsolana",
      "marketplaceUrl": "https://www.magiceden.io/marketplace/meerkat_millionaires_country_club",
      "discordUrl": "https://discord.com/invite/MMCC"
    },
  },
  {
    "id": "bf4c00c6-d5c3-411f-a4e9-eaf07c169ba0",
    "coinImageUrl": "https://i.imgur.com/rXTYGMZ.png",
    "logoUrl": "https://i.imgur.com/rXTYGMZ.png",
    "name": "Solana NFT Radar",
    "profileImageUrl": "https://i.imgur.com/rXTYGMZ.png",
    "url": "https://flip.solnftradar.com",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/solnftradar",
      "marketplaceUrl": "https://magiceden.io/marketplace/degen_coin_flip",
      "discordUrl": "http://discord.gg/nftradar"
    },
    "slug": "solnftradar"
  },
  {
    "id": "63a44135-31db-44d8-b539-b62262112f9e",
    "coinImageUrl": "https://i.imgur.com/W8iu5dO.png",
    "logoUrl": "https://i.imgur.com/W8iu5dO.png",
    "name": "Doge Capital",
    "profileImageUrl": "https://i.imgur.com/W8iu5dO.png",
    "url": "https://flip.thedogecapital.com ",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/thedogecapital",
      "marketplaceUrl": "https://magiceden.io/marketplace/doge_capital",
      "discordUrl": "https://discord.com/invite/DeM5VFF29v"
    },
    "slug": "dogecapital"
  },
  {
    "id": "c458b174-676f-4ab2-a7e7-d677ecc61c22",
    "coinImageUrl": "https://i.imgur.com/mcMfLF9.png",
    "logoUrl": "https://i.imgur.com/mcMfLF9.png",
    "name": "Solana Slugs",
    "profileImageUrl": "https://i.imgur.com/mcMfLF9.png",
    "url": "https://flip.solslugs.com",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/SolSlugsNFT",
      "marketplaceUrl": "https://magiceden.io/marketplace/sol_slugs",
      "discordUrl": "discord.gg/TKpFrWwa"
    },
    "slug": "solslugs"
  },
  {
    "id": "01aa6abe-feb1-48ef-a2b0-bc5618ab81a9",
    "coinImageUrl": "https://i.imgur.com/2KcwYfe.png",
    "logoUrl": "https://i.imgur.com/j07P8tg.gif",
    "name": "Bōryoku Dragonz",
    "profileImageUrl": "https://i.imgur.com/2KcwYfe.png",
    "url": "https://tajfeet.io",
    "themes": ["dark", "light"],
    "social": {
      "twitterUrl": "https://twitter.com/boryokudragonz",
      "marketplaceUrl": "https://magiceden.io/marketplace/boryoku_dragonz",
      "discordUrl": "https://discord.gg/jSUWCYm3rF"
    },
    "slug": "BoryokuDragonz"
  }
]

export const constants = {
  LOGO: {
    Default: "https://i.imgur.com/896fn7R.png"
    // Default: "https://i.imgur.com/PcpvtJg.png"
  },
  BUTTONS: {
    DoubleOrNothing: "https://i.imgur.com/gMlDK1J.png",
    Yolo: "https://i.imgur.com/kfsT8We.png",
    ClaimReward: "https://i.imgur.com/68rdjJv.png",
    Heads: "https://i.imgur.com/wc7Ci3f.png",
    Tails: "https://i.imgur.com/wxXcGls.png",
    TwoSol: "https://i.imgur.com/eS5gbgf.png",
    OneSol: "https://i.imgur.com/D8b7RNF.png",
    PtOneSol: "https://i.imgur.com/o6IEhiu.png",
    PtFiveSol: "https://i.imgur.com/iChJqD2.png",
    Pt25Sol: "https://i.imgur.com/5lRVCML.png",
    CoinHeads: "https://i.imgur.com/vQqakuK.png",
    CoinTails: "https://i.imgur.com/cFEB8Wg.png",
    ClaimFee: "https://i.imgur.com/rKnaYP9.png",
    BabyCoin: "https://i.imgur.com/AUUGX4z.gif",
    BabyCoinButt: "https://i.imgur.com/pp6kuh9.gif",
    BabyButton: "https://i.imgur.com/LnRn1mC.png",
    TryAgain: "https://i.imgur.com/Qvq0Dvg.png",
    PleaseWait: "https://i.imgur.com/x1WRFzM.png",
    FlipYourCard: "https://i.imgur.com/6oqHz96.png"
  },
  GIFS: {
    CoinSide: "https://i.imgur.com/VxoSlqK.gif"
  }
}


export const ERRORS = {
  DEPOSIT_FAILED: {
    code: 1001,
    type: "Solana Network Error",
    message: "Your SOL could not be deposited. Wait a moment, and try again."
  },
  REWARD_PROCESSING: {
    code: 1002,
    type: "Solana Network Error",
    message: "Your SOL reward is still processing. Wait a moment, and try again.",
  },
  FLIP_FAILED: {
    code: 1002,
    type: "Solana Network Error",
    message: "Your deposited SOL is processing. Wait a moment, and try again.",
  },
  PREV_PROCESSING: {
    code: 1003,
    type: "Please Wait",
    message: "Sorry! Solana is still processing your previous coin flip...",
  },
  NO_MORE_FLIPS: {
    code: 1004,
    type: 'This NFT Cannot Be Flipped',
    message: "Not a valid players card NFT or it has already been flipped the maximum of 5 times."
  }
}

export const CoinFlipStatus = {
  STARTED: "STARTED",
  FINALIZED: "FINALIZED",
  FINALIZE_REQUESTED: "FINALIZE_REQUESTED"
};


export const getOppositeSide = (side: any) => {
  if (side === "H") return "T";
  return "H";
}


export const PlayersCardTiers = {
  DEGEN: 1,
  ROSE_GOLD: 2,
  SAPPHIRE: 3, 
  DIAMOND: 4,
  ELITE: 5,
  BASED: 6
};

export const PlayersCardStaticJpegs = [
  "",
  "https://i.imgur.com/lFd9HUU.png",
  "https://i.imgur.com/lbbm0qk.png",
  "https://i.imgur.com/0HRuZ8P.png",
  "https://i.imgur.com/J0XSbFD.png",
  "https://i.imgur.com/BfFCZd3.png",
  "https://i.imgur.com/0Ttr0ue.png"
];


export const PlayersCardFlipSame = [
  "",
  "https://i.imgur.com/c0rx3dz.gif",
  "https://i.imgur.com/atwoPfa.gif",
  "https://i.imgur.com/U74oQDU.gif",
  "https://i.imgur.com/ZPeJNAP.gif",
  "https://i.imgur.com/A05unVo.gif"
];



export const PlayersCardFlipChange = [
  "",
  "https://i.imgur.com/nje80Fy.gif",
  "https://i.imgur.com/isr690u.gif",
  "https://i.imgur.com/43Uo8VU.gif",
  "https://i.imgur.com/MikAJLD.gif",
  "https://i.imgur.com/SNGoHUL.gif"
];