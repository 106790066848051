import { Modal } from "react-bootstrap";

export function SlowTPSModal({ styleCss, ...props }) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card card-user shadow-lg">
                    <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')}>
                        <h4 className="">Hey Degen, heads up!</h4>
                        DCF performs best when Solana's TPS (transactions per sec) is above 1.5K. You can still flip! But it may be slower than you’re used to.
                        <br />
                        <br />
                        <p className="text-danger font-weight-bold">
                            If you are playing during bad TPS <b>you will</b> run into UI errors. You may do a flip on-chain but you don't see it in our UI. 
                            This is sadly unavoidable during network congestion. 
                        </p>
                        We are working on work arounds but rely on Solana's network. See Solana's status <a href="https://explorer.solana.com" target="_blank">here</a>.
                    </div>
                    <div className="card-footer">
                        <button
                            className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning"
                            onClick={props.onClose}>
                            GOT IT
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}