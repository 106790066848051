import { useWalletNfts } from "@nfteyez/sol-rayz-react";
import { Connection } from "@solana/web3.js";
import { useState } from "react";
import { NftCard } from "../NftCard";
import { RPC_URL } from "../utils/constants";

export const SelectPlayersCard = ({ onContinue, wallet }) => {
    const PAGE_SIZE = 6;
    const [currentInventoryIndex, setCurrentInventoryIndex] = useState(PAGE_SIZE);
    let { nfts: nftEyes, isLoading, error } = useWalletNfts({
        publicAddress: wallet?.publicKey?.toString(),
        connection: new Connection(RPC_URL, "processed"),
    });
    const [selectedCard, setCard] = useState(null);

    const selectCard = (card) => {
        setCard(card);
    }

    const continueToNextPage = () => {
        onContinue(selectedCard);
    }

    return (
        <div className="card-body text-center">
            <div className="row">
                {
                    nftEyes?.filter(nft => nft?.data?.symbol == "DCFCARD")?.slice(currentInventoryIndex - PAGE_SIZE, currentInventoryIndex)?.map((nft) => (
                        <NftCard key={nft.mint} details={nft} selected={nft?.mint === selectedCard?.mint} onSelect={selectCard} />
                    ))
                }
                {
                    !isLoading && nftEyes?.filter(nft => nft?.data?.symbol == "DCFCARD")?.length == 0 &&
                    <><h6>NO PLAYERS CARDS FOUND!</h6></>
                }
            </div>
            <div className="d-flex">
                <span
                    onClick={() => currentInventoryIndex === PAGE_SIZE ? null : setCurrentInventoryIndex(currentInventoryIndex - PAGE_SIZE)}
                    className={currentInventoryIndex === PAGE_SIZE ? "fas fa-chevron-left me-auto text-secondary d-none" : "fas fa-chevron-left me-auto cursor-pointer"}>
                </span>
                <span
                    onClick={() => currentInventoryIndex >= nftEyes?.filter(nft => nft?.data?.symbol == "DCFCARD")?.length ? null : setCurrentInventoryIndex(currentInventoryIndex + PAGE_SIZE)}
                    className={currentInventoryIndex >= nftEyes?.filter(nft => nft?.data?.symbol == "DCFCARD")?.length ? "fas fa-chevron-right ms-auto text-secondary d-none" : "fas fa-chevron-right ms-auto cursor-pointer"}>
                </span>
            </div>

            <button
                className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning"
                onClick={() => continueToNextPage(selectedCard)}
                disabled={!selectedCard}>
                CONTINUE
            </button>
            <h6 className="mt-3">Phantom and SolScan cache images, but they will update periodically.</h6>
        </div>
    )
}
