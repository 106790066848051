import { Modal } from "react-bootstrap";
export const StuckModal = ({ styleCss, ...props }) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card card-user shadow-lg">
                    <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')}>
                        <h4 className="">ARE YOU STUCK?</h4>
                        Solana may still be processing your previous flip.<br/><br/> This may result in a UI glitch where you see your previous coin flip's result. 
                        <br/><br/>We will not double transact you without completing a flip. Pause for a bit, re-evaluate your strategy, and then try again once Solana syncs up.
                        <br/><br/>
                        Join our <a href="https://discord.gg/EbKC53qVWZ" target="_blank">discord</a> for more support.
                    </div>
                    <div className="card-footer">
                        <button
                            className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning"
                            onClick={() => { props.onHide() }}>
                            GOT IT
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}